'use client';
import { ReactNode } from 'react';
import { useThemeContext } from '@/src/contexts/theme/ThemeContext';
import styles from './SharedLayout.module.scss';
import { inter } from '@/src/fonts';

type LayoutProps = {
  children: ReactNode;
  header: ReactNode;
  footer: ReactNode;
};

export function VaultLayout({ children, header, footer }: Readonly<LayoutProps>) {
  const { theme, renderHeader, renderFooter } = useThemeContext();

  return (
    <>
      <div
        className={`${styles.pageBackdrop} ${theme === 'light' ? `${styles.light} lightPage` : `${styles.dark} darkPage`} ${inter.className}`}
        style={{ opacity: !theme ? '0' : '1' }}
      >
        {renderHeader && header}
        <div className={styles.scrollContainer} id={'scrollContainer'}>
          <main className={`${styles.pageOverlay} page-main`}>
            <div className={styles.pageContainer}>{children}</div>
          </main>
          {renderFooter && footer}
        </div>
      </div>
    </>
  );
}
